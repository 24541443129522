.auth-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.wrapper {
    overflow: hidden;
    margin: 24px;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0px 15px 20px rgba(255, 255, 255, 0.1);
    background: #202020;
    -webkit-transition: max-height 0.3s ease-in-out, box-shadow 0.6s linear;
    -moz-transition: max-height 0.3s ease-in-out, box-shadow 0.6s linear;
    -o-transition: max-height 0.3s ease-in-out, box-shadow 0.6s linear;
    -ms-transition: max-height 0.3s ease-in-out, box-shadow 0.6s linear;
    transition: max-height 0.3s ease-in-out, box-shadow 0.6s linear;
}

.wrapper .title-text {
    display: flex;
    width: 200%;
}

.wrapper .title {
    width: 50%;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    transition: all 0.6s ease;
}

.wrapper .slide-controls {
    position: relative;
    display: flex;
    height: 50px;
    width: 100%;
    overflow: hidden;
    margin: 8px 0 10px 0;
    justify-content: space-between;
    border: 1px solid #7E57C2;
    border-radius: 72px;
}

.slide-controls .slide {
    height: 100%;
    width: 100%;
    color: #f9fafc;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.6s ease;
}

.slide-controls label.signup {
    color: #f9fafc;
}

.slide-controls .slider-tab {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    z-index: 0;
    border-radius: 72px;
    background: -webkit-linear-gradient(left, #9575cd, #7E57C2, #7E57C2);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"] {
    display: none;
}

#signup:checked~.slider-tab {
    left: 50%;
}

#signup:checked~label.signup {
    color: #f9fafc;
    cursor: default;
    user-select: none;
}

#signup:checked~label.login {
    color: #ededed;
}

#login:checked~label.signup {
    color: #ededed;
}

#login:checked~label.login {
    cursor: default;
    user-select: none;
}

.wrapper .form-container {
    width: 100%;
    overflow: hidden;
}

.form-container .form-inner {
    display: flex;
    width: 200%;
}

.form-container .form-inner .form {
    width: 50%;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-inner .form .field {
    height: 50px;
    width: 100%;
    margin-top: 20px;
}

.form-inner .form .field input {
    height: 100%;
    width: 100%;
    outline: none;
    padding-left: 16px;
    border-radius: 16px;
    border: 1px solid #1b1b1b;
    border-bottom-width: 2px;
    font-size: 18px;
    transition: all 0.3s ease;
}

.form-inner .form .field input:focus {
    border-color: #9575cd;
}

.form-inner .form .field input::placeholder {
    color: #999;
    transition: all 0.3s ease;
}

.form .field input:focus::placeholder {
    color: #9575cd;
}

.form-inner .form {
    margin-top: 5px;
}

.form-inner .form .signup-link {
    text-align: center;
    margin-top: 30px;
}

.form-inner .form .signup-link {
    color: #9575cd;
    text-decoration: none;
}

.form-inner .form .signup-link {
    text-decoration: underline;
}

.form .btn {
    height: 50px;
    width: 100%;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
}

.form .btn .btn-layer {
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    background: -webkit-linear-gradient(left, #9575cd, #7E57C2, #7E57C2);
    border-radius: 24px;
    transition: all 0.4s ease;
}

.form .btn:hover .btn-layer {
    left: 0;
}

.form .btn input[type="submit"] {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    background: none;
    border: none;
    color: #f9fafc;
    padding-left: 0;
    border-radius: 24px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.alert-btn-container {
    border-radius: 48px;
}

.alert-btn {
    z-index: 1;
    position: relative;
    background: none;
    border: none;
    color: #f9fafc;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 4px 8px 4px 8px;
}

.modal-title {
    color: #f9fafc;
    font-size: 28px;
    font-weight: 600;
}


.modal-body {
    font-size: 20px;
}

.checkbox-label {
    margin-left: 6px;
}

.show-pass {
    margin-top: 16px;
    margin-left: 4px;
}

.form-check-input:checked {
    background-color: #7E57C2;
    border-color: #7E57C2;
}

.modal-content {
    border-radius: 16px;
    background-color: #181818;
    color: #f9fafc;
}

.btn-close {
    /* tint svg image */
    filter: invert(100%);
}