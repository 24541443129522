.dash-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    transition: all 0.6s ease;
}

.dash-heading {
    position: sticky;
    top: 0;
    background-color: #121212;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 0;
    width: 100%;
    z-index: 1;
}

.links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 20px;
    width: 90vw;
    margin-top: 20px;
    margin-bottom: 24px;
}

@media (max-width: 600px) {
    .links-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: repeat(2, 1fr);
    }
}

@media (max-width: 400px) {
    .links-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: repeat(1, 1fr);
    }
}


.no-background {
    background: none;
    border: none;
}